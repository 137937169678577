import { connect } from 'react-redux';

import {
    CategoryProductListContainer as SourceCategoryProductListContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CategoryProductList/CategoryProductList.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/CategoryProductList/Container */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {
    // TODO implement logic
    containerProps() {
            const {
                filter,
                isPageLoading,
                pages,
                search,
                selectedFilters,
                sort,
                totalItems,
                totalPages,
                isPlp,
                category = {},
            } = this.props;
    
            return {
                category,
                filter,
                isPageLoading,
                pages,
                search,
                selectedFilters,
                sort,
                totalItems,
                totalPages,
                isLoading: this.getIsLoading(),
                isPreventRequest: this.getIsPreventRequest(),
                isPlp,
                mix: { block: 'CategoryProductList', mods: { layout: this.getLayout() } },
            };
        }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
