import {
    CategoryDispatcher as SourceCategoryDispatcher,
} from 'SourceStore/Category/Category.dispatcher';
import CategoryQuery from 'Query/Category.query';
import { updateCurrentCategory } from 'Store/Category/Category.action';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import history from 'Util/History';
import { getQueryParam, setQueryParams } from 'Util/Url';
import agtm from '../../gtm/tags';

export class CategoryDispatcher extends SourceCategoryDispatcher {
    // TODO implement logic
    dataLayer = {};

    onSuccess(
        data,
        dispatch,
        { isSearchPage },
    ) {
        const {
            category,
            category: { id },
        } = data;
        
        if (!id && !isSearchPage) {
            dispatch(updateNoMatch(true));
        }
        // Call GTM for Category View
        // console.log("Current Category data: ", data);
        const { location } = history;
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');

        const selectedSortString = (getQueryParam('sortKey', location) || '');
        const selectedSortDirectionString = (getQueryParam('sortDirection', location) || '');
        const selectedPageNumber = (getQueryParam('page', location) || 1);

        
        // console.log("selectedFiltersString: ", selectedFiltersString);
        // console.log("selectedSortString: ", selectedSortString);
        // console.log("selectedSortDirectionString: ", selectedSortDirectionString);

        const selectedFilters = selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [ key ]: value.split(',') };
        }, {});

        const selectedSort = (selectedSortString != '') ? selectedSortString + ': ' + selectedSortDirectionString : '';

        // console.log("selectedFilters: ", selectedFilters);
        // console.log("selectedSort: ", selectedSort);

        // this.gtmCategoryView(category, selectedFilters, selectedSort, selectedPageNumber);
        
        dispatch(updateCurrentCategory(category));
    }

    gtmCategoryView(category, selectedFilters, selectedSort, selectedPageNumber) {
        const referalPageUrl = document?.referrer || window.location.href;
        this.dataLayer['Category Id'] = category.id;
        this.dataLayer['Category Name'] = category.name;
        this.dataLayer['Item Count'] = category.product_count;
        this.dataLayer['Sort'] = selectedSort || category.default_sort_by;
        this.dataLayer['Filter'] = selectedFilters;
        this.dataLayer['Pagination'] = selectedPageNumber;
        this.dataLayer['Page Type'] = category.display_mode;
        this.dataLayer['Referrer Page'] = referalPageUrl;
        this.dataLayer['URL'] = window.location.href;

        const date_time = `${Math.floor(new Date() / 1000)}`;
        let contentCategory = category.name;
        let contentName = category.name;
        let contentType = 'product_group';
        // let contentIds = [];
        // let contents = [];
        let itemsCount = 0;
        let contentIds = [category.id]
        let contents = [{id: category.id, items_count: category.product_count}]
        // for(let i in category.items){
        //     contentIds.push(category.items[i]._id)
        //     contents.push({id:category.items[i]._id , quantity:category.items[i].stock.quantity?category.items[i].stock.quantity:1})
        // }
        agtm.triggerCategoryView(this.dataLayer, category.items || [], category.name, 'Listing Page', category.url_path, {date_time, contentIds, contents, contentType,  contentName, contentCategory});
    }
};

export default new CategoryDispatcher();
