import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Page } from 'Component/Header/Header.config';
import { scrollToTop } from 'Util/Browser';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { getQueryParam } from 'Util/Url';
import agtm from '../../gtm/tags';
import history from 'Util/History';
import { isEmpty, isNaN, has, isSet } from 'lodash';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    // TODO implement logic

    componentDidUpdate(prevProps) {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
            category = {},
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
            pages: prevPages,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (
            JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || currentPage !== prevPage
        ) {
            window.isPrefetchValueUsed = false;
        }

        const { location } = history;
        const selectedPageNumber = (getQueryParam('page', location) || 1);
        let items = pages[selectedPageNumber] || [];
        let prevPagesItems = prevPages[selectedPageNumber] || [];
        if(has(category, "name")) {
            // console.log(" Calling GTM category:", category);
            this.callGTMForCategoryView();
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp) {
            return;
        }

        if (search !== prevSearch
            || currentPage !== prevPage
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

    callGTMForCategoryView() {
        const {
            sort,
            search,
            filter,
            pages,
            category,
        } = this.props;
        
        // console.log("callGTMForCategoryView this.props: ", this.props);

        const { location } = history;
        // console.log("location: ", location);
        const selectedFiltersString = (getQueryParam('customFilters', location) || '').split(';');
        // const selectedFiltersString = '';
        const selectedPageNumber = (getQueryParam('page', location) || 1);
        let items = pages[selectedPageNumber] || [];
        // console.log("items: ", items);
        const selectedSortString = (getQueryParam('sortKey', location) || '');
        const selectedSortDirectionString = (getQueryParam('sortDirection', location) || '');
        const selectedSort = (selectedSortString != '') ? selectedSortString + ': ' + selectedSortDirectionString : '';
        const selectedFilters = selectedFiltersString.reduce((acc, filter) => {
            if (!filter) {
                return acc;
            }
            const [key, value] = filter.split(':');

            return { ...acc, [ key ]: value.split(',') };
        }, {});
        
        this.gtmCategoryView(category, items, selectedFilters, selectedSort, selectedPageNumber);
    }

    gtmCategoryView(category, items, selectedFilters, selectedSort, selectedPageNumber) {
        let dataLayer = [];
        const referalPageUrl = document?.referrer || window.location.href;
        dataLayer['Category Id'] = has(category, "id") ? category.id : '';
        dataLayer['Category Name'] = has(category, "name") ? category.name : '';
        dataLayer['Item Count'] = items.length;
        dataLayer['Sort'] = selectedSort || has(category, "default_sort_by") ? category.default_sort_by : '';
        dataLayer['Filter'] = selectedFilters;
        dataLayer['Pagination'] = selectedPageNumber;
        dataLayer['Page Type'] = has(category, "display_mode") ? category.display_mode : '';
        dataLayer['Referrer Page'] = referalPageUrl;
        dataLayer['URL'] = window.location.href;

        const date_time = `${Math.floor(new Date() / 1000)}`;
        let contentCategory = has(category, "name") ? category.name : '';
        let contentName = has(category, "name") ? category.name : '';
        let urlPath = has(category, "url_path") ? category.url_path : '';
        let contentType = 'product_group';
        let contentIds = [];
        let contents = [];
        let itemsCount = 0;
        // let contentIds = [category.id]
        // let contents = [{id: category.id, items_count: category.product_count}]
        for(let i in items){
            contentIds.push(items[i].id)
            contents.push({id:items[i].id , quantity:items[i].salable_qty?items[i].salable_qty:0})
        }
        agtm.triggerCategoryView(dataLayer, items, contentCategory, 'Listing Page', urlPath, {date_time, contentIds, contents, contentType,  contentName, contentCategory});
    }

    requestPage(currentPage = 1, isNext = false) {
        const {
            category = {},
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            location: { pathname },
        } = this.props;
        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };
        // if (!isPrefetched) {
        //     requestProductList(options);
        // }
        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);

            if (!device.isMobile) {
                scrollToTop();
            }
            // waitForPriorityLoad().then(
            // /** @namespace Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
            //     () => requestProductListInfo(infoOptions),
            // );

            // if (!device.isMobile) {
            //     scrollToTop();
            // }
        }
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer,
    ),
);
