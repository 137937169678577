import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';

import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { prepareQuery } from 'Util/Query';
import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { executeGet } from 'Util/Request/Request';
import agtm from './../../gtm/tags';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace AlmusbahblendPwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    // TODO implement logic

    containerFunctions = {
        addToCart: this.addToCart.bind(this),

        // Used to update entered and selected state values
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),

        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this),
        scrollOptionsIntoView: this.scrollOptionsIntoView.bind(this),
        updateAddToCartTriggeredWithError: this.updateAddToCartTriggeredWithError.bind(this),
        wishlistcallForGtm: this.wishlistcallForGtm.bind(this),
    };

    /**
     * Event that validates and invokes product adding into cart
     * @returns {*}
     */
    async addToCart() {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();
        const activeProd = this.getActiveProduct();
        // console.log("addToCart getActiveProduct: ", activeProd);
        // console.log("addToCart getMagentoProduct: ", products);
        await addProductToCart({ products, cartId })
            .then(() => {
                    this.gtmAddToCart(activeProd, products[0].quantity);
                }

            )
            .catch(
                /** @namespace Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                },
            );
    }

    wishlistcallForGtm(add = true, wishlistProduct) {
        const activeProd = this.getActiveProduct();
        if (add) {
            this.gtmAddToWishlist(activeProd);
        } else {
            this.gtmRemoveFromWishlist(activeProd);
        }

    }

    async gtmAddToCart(product, qty) {
        
        let sku = product.sku;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Adding To Cart'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku , quantity:qty}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "add",
            "ProductPage",
            "AddToCartClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );
    }

    async gtmAddToWishlist(product) {
        
        let sku = product.sku;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        // const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Adding To Wishlist'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "addtowishlist",
            "ProductPage",
            "AddToWishlistClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );
    }

    async gtmRemoveFromWishlist(product) {
        
        let sku = product.sku;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        // const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Remove From Wishlist'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "removefromwishlist",
            "ProductPage",
            "RemoveFromWishlistClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer,
);
