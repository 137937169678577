
import { Suspense } from 'react';
import {
    AddToCart as SourceAddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
    ProductComponent as SourceProductComponent,
} from 'SourceComponent/Product/Product.component';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
};

// TODO: implement AddToCart
export const AddToCart = SourceAddToCart;


/** @namespace AlmusbahblendPwa/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    // TODO implement logic
    renderWishlistButton() {
        const { magentoProduct, isWishlistEnabled, wishlistcallForGtm } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductWishlistButton
                  magentoProduct={ magentoProduct }
                  wishlistcallForGtm= { wishlistcallForGtm }
                  mix={ {
                      block: this.className,
                      elem: 'WishListButton',
                  } }
                />
            </Suspense>
        );
    }
}


export default ProductComponent;
